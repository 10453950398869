<template>
  <b-card no-body>
    <b-card-header>
      <b-row>
        <b-col>
          Active Load Tenders
          <span v-if="tenders != null">({{ tenders.length }})</span>
          <span v-if="sortBy != null && sortBy.length > 0"
            >, Sorting By: <b>{{ sortText }}</b>
            {{ sortDesc ? "Descending" : "Ascending" }}
          </span>
        </b-col>
      </b-row>
    </b-card-header>
    <b-card-body v-if="tenders != null">
      <b-row class="table-responsive">
        <b-table
          :items="tenders"
          :fields="headers"
          :tbody-tr-class="rowClass"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          responsive="md"
          primary-key="shipmentId"
          :tbody-transition-props="transProps"
          striped
          small
          sort-icon-left
        >
          <template #cell(shipmentId)="data">
            <font-awesome-icon
              icon="print"
              @click="printShipmentClicked(data.item.id)"
            />&nbsp;
            <span class="clickable" @click="shipmentIdClicked(data.item.id)">{{
              formatShipmentId(data.item)
            }}</span>
          </template>
          <template #cell(trailer)="data">
            <span class="clickable" @click="trailerClicked(data.item)">
              {{ formatTrailer(data.item) }}
            </span>
          </template>
          <template #cell(from)="data">
            <span :title="locDetails(data.item.shipmentJson.stops[0].shipFrom)">
              {{ data.item.shipmentJson.stops[0].shipFrom.name }}
            </span>
          </template>
          <template #cell(to)="data">
            <span :title="locDetails(data.item.shipmentJson.stops[1].shipTo)">
              {{ data.item.shipmentJson.stops[1].shipTo.name }}
            </span>
          </template>
          <template #cell(status)="data">
            {{ debugStatus(data.item) }}
          </template>
          <template #cell(driver)="data">
            <b-button
              v-if="data.item.driverWorkDayRouteDriverJson == null"
              @click="showAssignLading(data.item)"
              title="Assign Driver"
              size="sm"
              class="btn"
            >
              <font-awesome-icon icon="plus" />
            </b-button>
            {{ formatDriver(null, null, data.item) }}
          </template>
          <template #cell(startedAt)="data">
            <b-button
              v-if="
                data.item.startedAt == null &&
                data.item.driverWorkDayRouteId != null
              "
              @click="startRoute(data.item)"
              title="Start Route"
              size="sm"
              class="btn"
            >
              <font-awesome-icon icon="play-circle" />
            </b-button>
            {{ formatStartedAt(null, null, data.item) }}
          </template>
          <template #cell(completedAt)="data">
            <b-button
              v-if="
                data.item.startedAt != null && data.item.completedAt == null
              "
              @click="endRoute(data.item)"
              title="End Route"
              size="sm"
              class="btn"
            >
              <font-awesome-icon icon="stop-circle" />
            </b-button>
            {{ formatCompletedAt(null, null, data.item) }}
          </template>
          <template #cell(actions)="data">
            <b-button v-if="
                data.item.startedAt == null &&
                data.item.completedAt == null &&
                data.item.driverWorkDayRouteId == null
              "
              title="Delete Tender"
              size="sm"
              class="btn"
              @click="deleteTenderId(data.item.id)"
            >
              <font-awesome-icon icon="window-close" />
            </b-button>
          </template>
        </b-table>
      </b-row>
    </b-card-body>
    <EdiAssignTender />
  </b-card>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import dateutil from "../../dateutil";
import { edi } from "../../api/integrationapi";
import { driverroutes } from "../../api/movementapi";
import EdiAssignTender from "./EdiAssignTender.vue";

export default {
  components: {
    EdiAssignTender,
  },
  props: {
    tenders: Array,
  },
  data() {
    return {
      transProps: {
        name: "fade-left",
      },
      sortBy: "shipBy",
      sortDesc: false,
      headers: [
        { key: "shipmentId", label: "Shipment", sortable: true },
        {
          key: "shipBy",
          label: "Ship By",
          formatter: "formatShipBy",
          sortable: true,
          sortByFormatted: true,
        },
        {
          key: "deliverBy",
          label: "Deliver By",
          formatter: "formatDeliverBy",
          sortable: true,
          sortByFormatted: true,
        },
        {
          key: "trailer",
          label: "Trailer",
          sortable: true,
          sortByFormatted: true,
        },
        { key: "from", label: "From" },
        { key: "to", label: "To" },
        { key: "driver", label: "Driver", formatter: "formatDriver" },
        {
          key: "startedAt",
          label: "Started At",
          formatter: "formatStartedAt",
          sortable: true,
          sortByFormatted: true,
        },
        {
          key: "completedAt",
          label: "Completed At",
          formatter: "formatCompletedAt",
        },
        {
          key: "actions",
          label: ""
        }
      ],
    };
  },
  watch: {
    tenders(newVal) {
      this.sortBy = this.sortBy;
    },
  },
  computed: {
    ...mapGetters("common", ["errorMessage", "messagingConnected"]),
    ...mapGetters("edi", ["pendingStates"]),
    sortText() {
      let st = null;
      if (this.sortBy == "shipBy") {
        st = "Ship By";
      }
      if (this.sortBy == "shipmentId") {
        st = "Shipment";
      }
      if (this.sortBy == "deliverBy") {
        st = "Deliver By";
      }
      if (this.sortBy == "startedAt") {
        st = "Started At";
      }
      return st;
    },
  },
  methods: {
    ...mapActions("edi", ["setSelectedTender","deleteTenderId"]),
    ...mapActions("common", ["showLoadingDuringAction"]),
    startRoute(edi204) {
      let r = { id: edi204.driverWorkDayRouteId };
      let c = driverroutes.startRoute(r);
      this.showLoadingDuringAction(c);
    },
    endRoute(edi204) {
      let r = { id: edi204.driverWorkDayRouteId };
      let c = driverroutes.endRoute(r);
      this.showLoadingDuringAction(c);
    },
    showAssignLading(edi204) {
      this.setSelectedTender(edi204);
      this.$bvModal.show("ediAssignTender");
    },
    formatShipBy(value, key, item) {
      return item.shipmentJson?.stops?.[0]?.shipBy
        ? this.formatDate(item.shipmentJson?.stops?.[0]?.shipBy, false)
        : "N/A";
    },
    formatTrailer(item) {
      return item?.shipmentJson?.equipment?.id;
    },
    formatDeliverBy(value, key, item) {
      return item.shipmentJson?.stops?.[1]?.deliverBy
        ? this.formatDate(item.shipmentJson?.stops?.[1]?.deliverBy, false)
        : "N/A";
    },
    formatDriver(value, key, item) {
      let d = null;
      if (item.driverWorkDayRouteDriverJson) {
        d = `${
          item.driverWorkDayRouteDriverJson.firstName
        } ${item.driverWorkDayRouteDriverJson.lastName.substring(0, 1)}`;
      }
      return d;
    },
    formatStartedAt(value, key, item) {
      return this.formatDate(item.startedAt, true);
    },
    formatCompletedAt(value, key, item) {
      return this.formatDate(item.completedAt, true);
    },
    formatShipmentId(tender) {
      return tender.shipmentId;
    },
    formatDate(d, local = true) {
      if (d) {
        return local
          ? dateutil.getLocalDateTime(d, "MM-DD h:mma")
          : dateutil.format(d, "MM-DD h:mma");
      } else {
        return "";
      }
    },
    locDetails(loc) {
      let d = null;
      if (loc) {
        d = `${loc?.name}\n${loc?.address1}\n${loc?.city}, ${loc?.state} ${loc?.zip}`;
      }
      return d;
    },
    getEdi204SheetLink(id) {
      return edi.generateEdi204SheetLink(id);
    },
    pendingState(id) {
      let r = null;
      let idx = this.pendingStates.map((p) => p.id).indexOf(id);
      if(idx > -1) {
        r = this.pendingStates[idx].state;
      }
      return r;
    },
    rowClass(tender, type) {
      if (!tender || type !== "row") {
        return;
      }
      let cls = "table-request";
      let pendingState = this.pendingState(tender.id);
      if (tender.accepted) {
        cls = "table-accepted";
      }
      if (tender.startedAt) {
        cls = "table-started";
      }
      if (tender.completedAt || pendingState == "completed") {
        cls = "table-completed";
      }
      if (tender.cancelled || pendingState == "removed") {
        cls = "table-cancelled";
      }
      return cls;
    },
    shipmentIdClicked(id) {
      window.open(edi.generateEdi204SheetLink(id), "_new");
    },
    printShipmentClicked(id) {
      window.open(`${edi.generateEdi204PdfSheetLink(id)}?inline=true`, "_new");
    },
    trailerClicked(item) {
      let newTrailer = window.prompt(
        "Enter the new trailer number",
        item?.shipmentJson?.equipment?.id,
      );
      if (newTrailer) {
        this.showLoadingDuringAction(edi.updateTrailer(item.id, newTrailer));
      }
    },
  },
};
</script>

<style scoped>
.btn {
  background-color: lightgrey;
  color: black;
}
</style>
