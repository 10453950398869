import { render, staticRenderFns } from "./RequestedLoadsPanel.vue?vue&type=template&id=4042e419&scoped=true"
import script from "./RequestedLoadsPanel.vue?vue&type=script&lang=js"
export * from "./RequestedLoadsPanel.vue?vue&type=script&lang=js"
import style0 from "./RequestedLoadsPanel.vue?vue&type=style&index=0&id=4042e419&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4042e419",
  null
  
)

export default component.exports