import Vue from "vue";
import { edi } from "../../api/integrationapi";
import { driverroutes } from "../../api/movementapi";

const state = {
  tenders: [],
  selectedTender: null,
  pendingStates: []
};

const getters = {
  pendingStates: (state) => {
    return state.pendingStates;
  },
  tenders: (state) => {
    state.tenders.sort((t1, t2) => {
      let t1time = t1.shipmentJson?.shipments?.[0]?.stops?.[0]?.shipBy
        ? t1.shipmentJson?.shipments?.[0]?.stops?.[0]?.shipBy
        : t1.dateReceived;
      let t2time = t2.shipmentJson?.shipments?.[0]?.stops?.[0]?.shipBy
        ? t2.shipmentJson?.shipments?.[0]?.stops?.[0]?.shipBy
        : t2.dateReceived;
      return t1time.localeCompare(t2time);
    });
    return state.tenders;
  },
  requestedTenders: (state) => {
    return state.tenders.filter((t) => !t.accepted);
  },
  activeTenders: (state) => {
    return state.tenders.filter((t) => t.accepted);
  },
  selectedTender: (state) => {
    return state.selectedTender;
  },
};

const actions = {
  setSelectedTender({ commit }, tender) {
    commit("setSelectedTender", tender);
  },
  async loadTenders({ commit, dispatch }) {
    commit("reset");
    let call = edi.loadTenders().then((res) => {
      res.data.payload.forEach((element) => {
        element.driverWorkDayRouteDriverJson = JSON.parse(
          element.driverWorkDayRouteDriverJson,
        );
        commit("addTender", element);
      });
    });
    return dispatch("common/showLoadingDuringAction", call, { root: true });
  },
  async acceptTenderId({ dispatch }, id) {
    let call = edi.acceptEdi204(id);
    return dispatch("common/showLoadingDuringAction", call, { root: true });
  },
  async denyTenderId({ dispatch }, id) {
    let call = edi.denyEdi204(id);
    return dispatch("common/showLoadingDuringAction", call, { root: true });
  },
  async deleteTenderId({ dispatch}, id) {
    let call = edi.deleteEdi204(id);
    return dispatch("common/showLoadingDuringAction", call, { root: true });
  },
  async getDriverWorkDaysForWorkDay({ dispatch }, workDay) {
    let call = driverroutes.getWorkDaysForDate(workDay).then((res) => {
      return res.data.payload;
    });
    return dispatch("common/showLoadingDuringAction", call, { root: true });
  },
  handleEdi204Mutation({ commit, rootGetters }, msg) {
    let mutation = JSON.parse(msg.body);
    //Edi204Created,Edi204Updated,Edi204Cancelled,Edi204Accepted,Edi204Denied,Edi204Started,Edi204Completed
    let notify = null;
    switch (mutation.action) {
      case "Edi204Created":
        commit("addTender", mutation.current);
        notify = `Shipment ${mutation.current.shipmentId} added`;
        break;
      case "Edi204Updated":
        commit("updateTender", mutation.current);
        notify = `Shipment ${mutation.current.shipmentId} updated`;
        break;
      case "Edi204Assigned":
        commit("updateTender", mutation.current);
        notify = `Shipment ${mutation.current.shipmentId} assigned`;
        break;
      case "Edi204Unassigned":
        commit("updateTender", mutation.current);
        notify = `Shipment ${mutation.current.shipmentId} unassigned`;
        break;
      case "Edi204Cancelled":
        commit("updateTender", mutation.current);
        notify = `Shipment ${mutation.current.shipmentId} cancelled`;
        commit("addPending", {id: mutation.current.id, state: "removed"});
        setTimeout(() => commit("removeTender", mutation.current), 10000);
        break;
      case "Edi204Deleted":
        commit("updateTender", mutation.previous);
        notify = `Shipment ${mutation.previous.shipmentId} deleted`;
        commit("addPending", {id: mutation.previous.id, state: "removed"});
        setTimeout(() => commit("removeTender", mutation.previous), 10000);
        break;
      case "Edi204Accepted":
        commit("updateTender", mutation.current);
        notify = `Shipment ${mutation.current.shipmentId} accepted`;
        break;
      case "Edi204Denied":
        commit("updateTender", mutation.current);
        notify = `Shipment ${mutation.current.shipmentId} denied`;
        commit("addPending", {id: mutation.current.id, state: "removed"});
        setTimeout(() => commit("removeTender", mutation.current), 10000);
        break;
      case "Edi204Started":
        commit("updateTender", mutation.current);
        notify = `Shipment ${mutation.current.shipmentId} started`;
        break;
      case "Edi204Completed":
        commit("updateTender", mutation.current);
        notify = `Shipment ${mutation.current.shipmentId} completed`;
        commit("addPending", {id: mutation.current.id, state: "completed"});
        setTimeout(() => commit("removeTender", mutation.current), 10000);
        break;
    }
    if (notify != null) {
      let user = rootGetters["security/userName"];
      if (user != mutation.by) {
        this.$app.$toasted.show(
          `${notify} by ${mutation.by.substring(0, mutation.by.indexOf("@"))}`,
        );
      } else {
        this.$app.$toasted.success(notify);
      }
    }
    //console.log(mutation);
  },
};

const mutations = {
  reset(state) {
    state.tenders = [];
    state.selectedTender = null;
  },
  setSelectedTender(state, tender) {
    state.selectedTender = tender;
  },
  addTender(state, tender) {
    tender.shipmentJson = JSON.parse(tender.shipmentJson);
    tender.waitTime = null;
    state.tenders.push(tender);
  },
  updateTender(state, tender) {
    tender.shipmentJson = JSON.parse(tender.shipmentJson);
    tender.driverWorkDayRouteDriverJson = JSON.parse(
      tender.driverWorkDayRouteDriverJson,
    );
    tender.waitTime = null;
    let idx = state.tenders.map((t) => t.id).indexOf(tender.id);
    if (idx > -1) {
      Vue.set(state.tenders, idx, tender);
    }
  },
  addPending(state, pending) {
    let idx = state.pendingStates.map((p) => p.id).indexOf(pending.id);
    if(idx < 0) {
      state.pendingStates.push(pending);
    }
  },
  removeTender(state, tender) {
    let idx = state.tenders.map((t) => t.id).indexOf(tender.id);
    if (idx > -1) {
      state.tenders.splice(idx, 1);
      idx = state.pendingStates.map((p) => p.id).indexOf(tender.id);
      if(idx > -1) {
        state.pendingStates.splice(idx,1);
      }
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
